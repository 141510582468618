import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  baseURL: 'https://ovpm-dy-my1.ssfox.io',
});

// 添加请求拦截器
api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;