import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiTitle,
    EuiPanel,
    EuiFieldText,
    EuiSpacer,
    EuiForm,
    EuiFormRow,
    EuiFieldPassword,
    EuiButton,
    EuiProvider,
    EuiCallOut,
    EuiLoadingSpinner,
} from '@elastic/eui';
import { useState } from 'react';
import api from '../utils/api';
import { useNavigate } from 'react-router-dom';

function LoginPage({ onLoginSuccess }: { onLoginSuccess: () => void }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const isLoginEnabled = username.trim() !== '' && password.trim() !== '';

    const handleLogin = async () => {
        setIsLoading(true);
        setError('');
        try {
            const response = await api.post('/api/login', {
                username,
                password
            });

            if (response.data.msg === "登录成功") {
                if (response.data.token) {
                    const now = new Date();
                    const item = {
                        token: response.data.token,
                        username: response.data.username, // 确保后端返回用户名
                        expiry: now.getTime() + 24 * 60 * 60 * 1000,
                    };
                    localStorage.setItem('userToken', JSON.stringify(item));
                }
                setError('登录成功');
                onLoginSuccess(); // 调用传入的回调函数
                navigate('/dashboard');
            } else {
                setError('登录失败：' + response?.data?.msg);
            }
        } catch (err) {
            setError('登录失败：网络错误');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <EuiProvider>
            <EuiFlexGroup gutterSize="xs" direction="column" justifyContent="flexStart" alignItems="center"  >
                <EuiFlexItem style={{ marginTop: "5%" }}>
                    <EuiTitle size="l">
                        <h1>NB_OVPM</h1>
                    </EuiTitle>
                </EuiFlexItem>
                <EuiPanel aria-live='polite' style={{ maxWidth: "90%", minWidth: 400, marginTop: "5%" }}>
                    <EuiTitle size='s' id="xx"><h1 style={{ textAlign: "center" }}>Log in</h1></EuiTitle>
                    <EuiSpacer size="m" />
                    <EuiForm component="form" onSubmit={(e) => e.preventDefault()}>
                        {error && (
                            <>
                                <EuiCallOut title="提示" color={error === '登录成功' ? 'success' : 'danger'}>
                                    <p>{error}</p>
                                </EuiCallOut>
                                <EuiSpacer size="m" />
                            </>
                        )}
                        <EuiFormRow label="Username">
                            <EuiFieldText
                                icon="user"
                                value={username}
                                onChange={(e) => { setError(''); setUsername(e.target.value) }}
                            />
                        </EuiFormRow>
                        <EuiSpacer size="l" />
                        <EuiFormRow label="Password">
                            <EuiFieldPassword
                                type="dual"
                                value={password}
                                onChange={(e) => { setError(''); setPassword(e.target.value) }}
                            />
                        </EuiFormRow>
                        <EuiSpacer size="xl" />
                        <EuiButton
                            fullWidth
                            fill
                            style={{ background: error ? "rgb(189 39 30)" : isLoginEnabled ? "black" : "gray" }}
                            onClick={handleLogin}
                            isDisabled={!isLoginEnabled || isLoading}
                        >
                            {isLoading ? <EuiLoadingSpinner size="m" /> : 'Log in'}
                        </EuiButton>
                        <EuiSpacer size="l" />
                    </EuiForm>
                </EuiPanel>
            </EuiFlexGroup>
        </EuiProvider>
    );
}

export default LoginPage;