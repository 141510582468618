export function getToken(): string | null {
    const tokenString = localStorage.getItem('userToken');
    if (tokenString) {
        const token = JSON.parse(tokenString);
        const now = new Date();
        if (now.getTime() > token.expiry) {
            // Token has expired
            localStorage.removeItem('userToken');
            return null;
        }
        return token.token;
    }
    return null;
}

export function removeToken(): void {
    localStorage.removeItem('userToken');
    window.location.reload();
}

export function getUsername(): string {
    const tokenString = localStorage.getItem('userToken');
    if (tokenString) {
        const token = JSON.parse(tokenString);
        return token.username || 'User';
    }
    return 'User';
}